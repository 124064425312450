<template>
  <div>
    <v-divider></v-divider>
        <v-col v-show="loading" cols="12" class="text-center mt-16">
          <h3 class="mb-10">Processing Payment...</h3>
          <v-progress-circular class= "mb-10"
              :size="50" :width="4"
              color="blue"
              indeterminate
          ></v-progress-circular>
        </v-col>
        <div v-show="!loading">
        
          <form id="payment-form">
            <v-container>
              <v-row dense>
                <v-col v-show="applePay || googlePay" cols="12" class="text-button">
                  PAY WITH DIGITAL WALLET
                </v-col>
                <v-col v-show="applePay!=null" cols="12" sm="6" class="ma-0 pa-0">
                  <div id="apple-pay-button"></div>
                </v-col>
                <v-col v-show="googlePay!=null" cols="12" sm="6" class="ma-0 pa-0">
                  <div  id="google-pay-button" ></div>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="6" class="text-button mt-2 mb-n2" >
                  <p style="line-height:1.5">PAY WITH CARD</p>
                </v-col>
              </v-row>  

              <v-row dense>
          
            <!--
            <v-spacer></v-spacer>
            <v-col cols="6" class="mt-0 mb-n4" style="display: flex; justify-content: flex-end">
              <v-checkbox v-show="paymentType==0 && !invitee" class="save-checkbox"
                color="black" label="SAVE CARD" dense
                @click="terms = true;" v-model="saveCardConfirmed"
              >
              </v-checkbox>
            </v-col>
            -->
            <v-col cols="12" md="8">
              <div id="card-container" ></div>
            </v-col>
            
            <!--
            <v-col v-if="userInfo.cards" cols="12" px-2 pb-2>
              <v-icon large red>fa-cc-visa</v-icon>
              <v-radio-group hide-details v-model="selectedSavedCard">
                <v-radio
                v-for="(card, index) in userInfo.cards"
                :key="index"
                :value="index"
              >
                  <div slot="label" class="body-2 font-weight-thin black--text">
                    {{card.card_brand + ' *' + card.last_4}}
                  </div>
                  </v-radio>
              </v-radio-group>
            </v-col>
            -->

            <v-col cols="12" md="2">
              <button id="card-button" type="button">Pay with Card</button>
            </v-col>
          </v-row>
          </v-container>
        </form>

        <div id="payment-status-container"></div>
          <template  v-if="errors && errors.length > 0">
            <div id="error" class="text-caption text-center mt-2">
                <p> {{ polishedErrors }} </p>
            </div>
          </template>
        </div>




    <v-dialog v-model="terms" width="70%">
      <v-card>
          <v-card-title class="title">Save Card On File</v-card-title>
          <v-card-text>
              Your credit card info will be encrypted and stored to your customer profile managed by Square Inc. 
              You can use cards on file for future orders in the BOTLS app, so please ensure that your password is secure.
              <br><br>
              Your card will be saved only if you enable this option and complete purchase.
          </v-card-text>
          <v-card-actions>
          <v-btn
              text
              color="black"
              @click="terms = false; saveCardConfirmed = false"
          >No Thanks</v-btn>
          <v-spacer></v-spacer>
          <v-btn
              text
              color="primary"
              @click="terms = false; saveCardConfirmed = true"
          >Save My Card</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {  mapGetters } from 'vuex'
export default {
  data: function() {
    return {
      errors: null,
      masterpass: false,
      loaded: false,
      loading: false,
      applePay: false,
      built: false,
      allowSaveCard: true,
      terms: false,
      saveCardConfirmed: false,
      paymentType: 0, //new card
      postalCode: "",
      selectedSavedCard: 0,
      applePay: null,
      googlePay: null,
      cardButton: null,
      paymentRequest: null,
      card: null,
    };
  },
  watch: {
    cartTotal(){
      if(this.cart != null && this.paymentRequest != null){
        const updateSuccessful = this.paymentRequest.update({ total: {amount: this.cartTotal, label: 'Total'}});
      }
    },
    showPaymentForm(val){
      //console.log("showPaymentForm: " + val)
      if(val && this.card){
        this.card.recalculateSize()
      }
    },
  },
  props: {
    showPaymentForm: Boolean,
    buttonText: String,
    id: Number,
  },
  mounted: function() {

    let externalScript = document.createElement('script')
    externalScript.setAttribute('src', ENV.webPaymentsScript())
    document.head.appendChild(externalScript)

    //wait until the script has been loaded before proceeding with square form creation
    let ctr = 0
    let self = this
    let handle = window.setInterval(function () {
        if (window.Square) {
            self.loaded = true
            clearInterval(handle)
            self.configureSquare()
        }
        ctr++
        if (ctr > 1000) { //wait 5 ms
            console.error("Unable to load square.js")
            clearInterval(handle)
        }
    }, 5)
  },
  computed: {
    ...mapGetters({
      cart: 'cart',
      userInfo: 'userInfo',
    }),
    invitee () {return this.userInfo.userStatus == "invitee"},
    polishedErrors() {
      if(this.errors == null)
        return null
      let message = ""
      this.errors.forEach( (error) => {
        message += error + " "
      })
      return message
    },
    cartTotal() {
      let dollars = '0.00'
      if(this.cart != null){
        dollars = (this.cart.total_money.amount / 100.0).toFixed(2)
      }
      return dollars
    },
  },
  methods: {
    getCardOnFileId () {
      if( this.choosePayment == "new")
        return null
      else{
        let cardIndex = this.selectedSavedCard
        return this.userInfo.cards[cardIndex].id
      }
    },

    //initialize the card entry form
    async initializeCard(payments) {
      const card = await payments.card();
      await card.attach('#card-container'); 
      return card; 
    },


    //build form for google pay
    buildPaymentRequest(payments) {
      if(this.paymentRequest == null){
        this.paymentRequest = payments.paymentRequest({
            countryCode: 'US',
            currencyCode: 'USD',
            total: {
              amount: this.cartTotal,
              label: 'Total',
            },
          });
      }
    },

    async initializeGooglePay(payments) {
      this.buildPaymentRequest(payments)
      const googlePay = await payments.googlePay(this.paymentRequest);
      await googlePay.attach('#google-pay-button');

      return googlePay;
    },

    async initializeApplePay(payments) {
      this.buildPaymentRequest(payments)
      const applePay = await payments.applePay(this.paymentRequest);
      // Note: You do not need to `attach` applePay.
      return applePay;
    },

    async configureSquare () {
      let locationId = ENV.squareLocationId(); 
      let applicationId = ENV.squareApplicationId();
      const payments = window.Square.payments(applicationId, locationId);
      let self = this;
      //let card;
      
      //configure pay with card
      try {
        this.card = await this.initializeCard(payments);
      } catch (e) {
        console.error('Initializing Card failed', e);
        return;
      }
      this.cardButton = document.getElementById('card-button');
      this.cardButton.addEventListener('click', async function (event) {
        await self.handlePaymentMethodSubmission(event, self.card);
      });

      //configure Apple Pay
      try {
        this.applePay = await this.initializeApplePay(payments);
      } catch (e) {
        console.error('Initializing Apple Pay failed', e);
        // There are a number of reason why Apple Pay may not be supported
        // (e.g. Browser Support, Device Support, Account). Therefore you should
        // handle
        // initialization failures, while still loading other applicable payment
        // methods.
      }
      if (this.applePay != null) {
        const applePayButton = document.getElementById('apple-pay-button');
        applePayButton.addEventListener('click', async function (event) {
          await self.handlePaymentMethodSubmission(event, self.applePay);
        });
      }

      //configure Google Pay
      try {
        this.googlePay = await this.initializeGooglePay(payments);
      } catch (e) {
        console.error('Initializing Google Pay failed', e);
        // There are a number of reason why Google Pay may not be supported
        // (e.g. Browser Support, Device Support, Account). Therefore you
        // should handle initialization failures, while still loading other
        // applicable payment methods.
      }
      if (this.googlePay != null) {
        const googlePayButton = document.getElementById('google-pay-button');
        googlePayButton.addEventListener('click', async function (event) {
          await self.handlePaymentMethodSubmission(event, self.googlePay);
        });
      }  
    
    },

    // This function tokenizes a payment method. 
    // The ‘error’ thrown from this async function denotes a failed tokenization,
    // which is due to buyer error (such as an expired card). It is up to the
    // developer to handle the error and provide the buyer the chance to fix
    // their mistakes.
    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === 'OK') {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(
            tokenResult.errors
          )}`;
        }
        throw new Error(errorMessage);
      }
    },

    // Helper method for displaying the Payment Status on the screen.
    // status is either SUCCESS or FAILURE;
    displayPaymentResults(status) {
      const statusContainer = document.getElementById(
        'payment-status-container'
      );
      if (status === 'SUCCESS') {
        statusContainer.classList.remove('is-failure');
        statusContainer.classList.add('is-success');
      } else {
        statusContainer.classList.remove('is-success');
        statusContainer.classList.add('is-failure');
      }

      statusContainer.style.visibility = 'visible';
    },
    
    async handlePaymentMethodSubmission(event, paymentMethod) {
      event.preventDefault();
      this.cardButton.disabled = true;
      this.$emit("freeze-cart", true)
      this.loading = true
      try {
        const token = await this.tokenize(paymentMethod);
        console.log("token result: " + token)
        const result = await this.submitPayment(token);
        console.log(result)
        if(result.data.status == "success"){
          this.displayPaymentResults('SUCCESS');
          this.$emit('payment-successful') //let product page know
          console.debug('Payment Success', result);
          //if(self.saveCardConfirmed) this.loadUserInfo() //get the new card added
        }
        else{
          this.displayPaymentResults('FAILURE');
          this.errors = result.data.errors
          console.log( result.data.errors)
        }
      } catch (e) {
        this.displayPaymentResults('FAILURE');
        console.error(e);
      }
      this.cardButton.disabled = false;
      this.$emit("freeze-cart", false)
      this.loading = false
    },

    async submitPayment (token) { //charges entered cc for whatever is in the cart

      var functionsCall = global.FIREBASE.functions.httpsCallable('purchasecart')
      let response = await functionsCall(
        { token: token, 
          //cardId: savedCardId,
          cart: this.cart, 
          customer: this.userInfo,
          //saveCard: self.saveCardConfirmed,
        }
      )
      return response

    }
  }
};
</script>

<style>

#card-input {
  margin-top: 0;
  margin-bottom: 40px;
}

  #card-button {
    color: #ffffff;
    background-color: #006aff;
    border-radius: 5px;
    cursor: pointer;
    border-style: none;
    user-select: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding: 12px;
    width: 100%;
    box-shadow: 1px;
  }

  #card-button:active {
    background-color: rgb(0, 85, 204);
  }

  #card-button:disabled {
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.3);
  }

  .save-checkbox .v-label {
  font-size: 12px;
  font-weight: 500;
} 

/*  ::v-deep .my-checkbox .v-label {
//  font-size: 10px;
  }
*/

  #card-container {
  margin-top: 0px;
  /* this height depends on the size of the container element */
  /* We transition from a single row to double row at 485px */
  /* Settting this min-height minimizes the impact of the card form loading */
  min-height: 70px;
}
/* @media screen and (max-width: 500px) {
  #card-container {
    min-height: 140px;
  }
} */



  #apple-pay-button {
    height: 42px;
    width: 100%;
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: plain;
    -apple-pay-button-style: black;
  }

  #payment-status-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 50px;
  margin: 0 auto;
  width: 225px;
  height: 48px;
  visibility: hidden;
}

#payment-status-container.missing-credentials {
  width: 350px;
}

#payment-status-container.is-success:before {
  content: '';
  background-color: #00b23b;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0968 6.31744 12.0978 5.68597 11.7093 5.29509C11.3208 4.90422 10.6894 4.90128 10.2973 5.28852L11 6C10.2973 5.28852 10.2973 5.28853 10.2973 5.28856L10.2971 5.28866L10.2967 5.28908L10.2951 5.29071L10.2886 5.29714L10.2632 5.32224L10.166 5.41826L9.81199 5.76861C9.51475 6.06294 9.10795 6.46627 8.66977 6.90213C8.11075 7.4582 7.49643 8.07141 6.99329 8.57908L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E");
  mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0968 6.31744 12.0978 5.68597 11.7093 5.29509C11.3208 4.90422 10.6894 4.90128 10.2973 5.28852L11 6C10.2973 5.28852 10.2973 5.28853 10.2973 5.28856L10.2971 5.28866L10.2967 5.28908L10.2951 5.29071L10.2886 5.29714L10.2632 5.32224L10.166 5.41826L9.81199 5.76861C9.51475 6.06294 9.10795 6.46627 8.66977 6.90213C8.11075 7.4582 7.49643 8.07141 6.99329 8.57908L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E");
}

#payment-status-container.is-success:after {
  content: 'Payment successful';
  font-size: 14px;
  line-height: 16px;
}

#payment-status-container.is-failure:before {
  content: '';
  background-color: #cc0023;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
  mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
}

#payment-status-container.is-failure:after {
  content: 'Payment failed';
  font-size: 14px;
  line-height: 16px;
}

#payment-status-container.missing-credentials:before {
  content: '';
  background-color: #cc0023;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  -webkit-mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
  mask: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L6.58579 8L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L8 9.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L9.41421 8L11.7071 5.70711C12.0976 5.31658 12.0976 4.68342 11.7071 4.29289C11.3166 3.90237 10.6834 3.90237 10.2929 4.29289L8 6.58579L5.70711 4.29289Z' fill='black' fill-opacity='0.9'/%3E%3C/svg%3E%0A");
}

#payment-status-container.missing-credentials:after {
  content: 'applicationId and/or locationId is incorrect';
  font-size: 14px;
  line-height: 16px;
}

#payment-status-container.is-success.store-card-message:after {
  content: 'Store card successful';
}

#payment-status-container.is-failure.store-card-message:after {
  content: 'Store card failed';
}
 </style>




</style>